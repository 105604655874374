<template>
  <v-app>
    <NavBar />
  </v-app>
</template>

<script>
import { defineComponent } from "vue";

// Components
import NavBar from "@/components/NavBar.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    NavBar,
  },
  async mounted() {
    await this.$store.dispatch("updatePageTitle", "Inicio");
    if (!this.$store.getters.userIsAuthenticated) {
      await this.$store.dispatch("resetStoreInfo");
      await this.$store.dispatch("updateShowDrawer", false);
      this.$router.push("/login");
    } else {
      const dateToday = new Date().toISOString().split("T")[0];
      const lastAuthDate = this.$store.getters.userLastAuthDate;
      if (dateToday !== lastAuthDate) {
        await this.$store.dispatch("resetStoreInfo");
        await this.$store.dispatch("updateShowDrawer", false);
        this.$router.push("/login");
      } else {
        this.$router.push("/scantickets");
      }
    }
  },
});
</script>
